<template>
  <div class="wrapper policy-lock">
    <div class="icon_close" @click="$router.go(-1)">
      <svg viewBox="0 0 12 12">
        <g clip-path="url(#clip0)">
          <path
              d="M7.09939 5.99996L11.772 1.32726C12.076 1.02332 12.076 0.531895 11.772 0.227955C11.468 -0.0759849 10.9766 -0.0759849 10.6726 0.227955L5.99994 4.90065L1.32737 0.227955C1.02329 -0.0759849 0.532002 -0.0759849 0.228062 0.227955C-0.0760207 0.531895 -0.0760207 1.02332 0.228062 1.32726L4.90063 5.99996L0.228062 10.6727C-0.0760207 10.9766 -0.0760207 11.468 0.228062 11.772C0.379534 11.9236 0.578697 11.9997 0.777717 11.9997C0.976737 11.9997 1.17576 11.9236 1.32737 11.772L5.99994 7.09927L10.6726 11.772C10.8243 11.9236 11.0233 11.9997 11.2223 11.9997C11.4213 11.9997 11.6203 11.9236 11.772 11.772C12.076 11.468 12.076 10.9766 11.772 10.6727L7.09939 5.99996Z"></path>
        </g>
        <clipPath id="clip0">
          <rect width="12" height="12"></rect>
        </clipPath>
      </svg>
    </div>

    <h3>Политика конфиденциальности</h3>
    <div class="policy_content">
      <h4>1. ОБЩИЕ ПОЛОЖЕНИЯ</h4>
      <p>
        1.1. Общество с ограниченной ответственностью «Сан Смайл» ОГРН 1147746843837 ИНН 7707839805, КПП 770701001,
        адрес местонахождения: 127055, г. Москва, ул. Палиха, д. 10, строение 9 (далее – «Компания»), обязуется
        соблюдать конфиденциальность данных и информации, предоставляемых пользователями («Пользователь») при
        использовании сайта <a href="https://lk-partners.eurokappa.ru" target="_blank">https://lk-partners.eurokappa.ru</a>, а также при
        пользовании и участии в чатах Компании в
        мессенджерах
        WhatsApp, Viber, Telegram (далее – «Мессенджеры» или «Мессенджер»).
      </p>
      <p>1.2. Данной Политикой устанавливаются правила, в соответствии с которыми Компания осуществляет сбор и/или
        обработку данных и информации, которые предоставляет Пользователь или которые становятся доступны Компании в
        процессе пользования Пользователем сайтом и/или Мессенджерами.</p>
      <p>
        1.3. Условием использования сайта и/или Мессенджера является согласие Пользователя с настоящей Политикой,
        размещенной на Сайте Компании по адресу: <a href="https://lk-partners.eurokappa.ru" target="_blank">https://lk-partners.eurokappa.ru</a>.
        При каждом доступе и/или фактическом использовании
        сайта и/или Мессенджера Пользователь соглашается с условиями настоящей Политики.
      </p>
      <h4>2. ОБРАБАТЫВАЕМЫЕ КОМПАНИЕЙ ДАННЫЕ И ИНАЯ ИНФОРМАЦИЯ</h4>
      <p>2.1. Пользователь дает согласие на обработку предоставленных данных, т.е. осуществление любых действий
        (операций) или совокупности действий (операций), совершаемых с использованием средств автоматизации или без
        использования таких средств, с предоставленными данными, в том числе персональными данными, включая сбор,
        запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование,
        передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение, в целях:
      </p>

      <ul>
        <li>передачи полученных от Пользователя данных третьим лицам для обработки заявки Пользователя, а именно
          партнерам
          и/или внешним специалистам, сотрудничающим с Компанией, и/или лицам, предоставившим заявку от Пользователя
          и/или
          передавшим заявку Пользователя Компании, и/или лицам, осуществляющим поддержку служб и услуг Компании, в
          необходимом для осуществления такой поддержки объеме, и/или компаниям, входящим с Компанией в группу компаний;
        </li>
        <li>ознакомления с информационными материалами от имени Компании, в том числе с рекламными материалами Компании
          и/или третьих лиц;
        </li>
        <li>защиты прав и законных интересов Компании;</li>
        <li>проведения статистических и прочих исследований на основе обезличенных данных.</li>
      </ul>

      <p>
        2.2. Пользователь соглашается, что информация, включая учетные и иные данные, может быть доступна другим
        пользователям сети Интернет с учетом существующего функционала Мессенджера.
      </p>

      <p>2.3. Обработка персональных данных, разрешенных для распространения, из числа специальных категорий
        персональных
        данных, указанных в ч. 1 ст. 10 Закона о персональных данных, допускается, если соблюдается запреты и условия,
        предусмотренные ст. 10.1 Закона о персональных данных.
        Согласие на обработку предоставленных данных дается на неопределенный срок и может быть в любой момент отозвано
        субъектом данных и/или его уполномоченным представителем путем письменного обращения в адрес Компании.
      </p>
      <p> 2.4. Компания обязуется в разумные сроки немедленно прекратить обработку предоставленных данных после
        получения
        соответствующего письменного требования субъекта данных и/или его уполномоченного представителя, за исключением
        случаев, когда Компания и/или лица, осуществляющие поддержку служб и услуг Компании, в необходимом для
        осуществления такой поддержки объеме, и/или компании, входящие с Компанией в группу компаний, вправе и/или
        обязаны продолжить обработку предоставленных данных, в том числе персональных данных, без согласия субъекта
        данных (субъекта персональных данных), при наличии оснований, установленных действующим законодательством
        Российской Федерации.</p>

      <h4>3. ДЕЙСТВИЕ НАСТОЯЩЕЙ ПОЛИТИКИ</h4>
      <p>
        3.1. Компания оставляет за собой право вносить изменения и дополнения в настоящую Политику. Новая редакция
        Политики вступает в силу с момента её размещения на сайте по адресу: <a href="https://lk-partners.eurokappa.ru" target="_blank">https://lk-partners.eurokappa.ru</a>.
        Пользователь обязуется
        самостоятельно регулярно знакомиться с новыми редакциями Политики.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy"
}
</script>

<style lang="less" scoped>
@import "src/assets/css/variables.less";

.policy-lock {
  .wrapper_template;

  color: #43BAC0;
  color: #163651;

  .icon_close {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .policy_content {
    width: 100%;
  }

  h3, h4 {
    margin: 15px 0px;
  }

  p {
    font-size: inherit;
    line-height: 1.45;
    font-weight: inherit;
    color: inherit;
    margin: 12px 0px;
  }
}
</style>