<template>
  <img src="@/assets/icon/other/bonus_ico.svg" alt="">
</template>

<script>
export default {
  name: "RubIco"
}
</script>

<style scoped>

</style>